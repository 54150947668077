import React, {useState} from "react"
import { slides } from "./constants"
import { ReactComponent as ArrowLeft } from '../../icons/functions-messenger/arrow-left-circle-line.svg';
import { ReactComponent as ArrowRight } from '../../icons/functions-messenger/arrow-right-circle-fill.svg'

import './FunctionsMessenger.css'

export const FunctionsMessenger = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const goToPreviousSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };
    
      const goToNextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };

      const isLeftDisabled = currentIndex === 0;
      const isRightDisabled = currentIndex === 3;
      
    return (
        <section className="functions-messenger">
        <div className='functions-messenger__container'>
          <div className="functions-messenger__content">
            <h3 className="functions-messenger__title">Все функции<br />классического<br />мессенджера</h3>
            <p className="functions-messenger__subtitle bodyM_Regular_22">Общайтесь, как привыкли, используя<br />знакомые и удобные функции.</p>
            <div className="functions-messenger__button-wrapper">
                <button 
                  className="functions-messenger__button" 
                  disabled={isLeftDisabled} 
                  onClick={goToPreviousSlide}
                >
                  <ArrowLeft className="arrow-left-circle" />
                </button>
                <button 
                  className="functions-messenger__button" 
                  disabled={isRightDisabled} 
                  onClick={goToNextSlide}
                >
                  <ArrowRight className="arrow-right-circle" />
                </button>
            </div>
          </div>
  
          <div className="functions-messenger__slider">
            <div 
              className="functions-messenger__slider-wrapper"
              style={{ transform: `translateX(-${currentIndex * 103}%)`, transition: 'transform 0.5s ease-in-out' }}
            >
              {slides.map((slide, index) => (
                <div key={index} className="functions-messenger__slide-item">
                  {slide}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    )
}
