import { Link } from 'react-router-dom';
import './PrivacyPolicyPage.css'

export const PrivacyPolicyPage = () => {
    return (
        <div className="privacy-policy__main">
            <header className="header__container">
                <div className="header__logo"><svg className="logo_blue"/></div>
    
                <button className="button button-contact">
                    <span className="button-text"><Link  to="/" className="header__link">Назад</Link></span>
                </button>
            </header>
            <section className="privacy-policy__container">
                <h3 className='privacy-policy__title'>Политика конфиденциальности</h3>
                <div className='privacy-policy__content'>
                    <h4 className='privacy-policy__subtitle'>Политика в отношении обработки персональных данных</h4>

                    <p className='bodyS_Regular_22'>
                       <br/>
                       <br/>
                       <h5> 1. Общие положения.</h5>
                       <br/>
                       <br/>
                        Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые Михайловым Иваном Сергеевичем (далее — Оператор).
                        <br/>
                        <br/>
                        1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.<br/>
                        1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта httpsː//thismywebsite·com.<br/>
                        2. Основные понятия, используемые в Политике<br/>
                        2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.<br/>
                        2.2. Блокирование персональных данных — временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных).<br/>
                        2.3. Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу httpsː//thismywebsite·com.<br/>
                        2.4. Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных технологий и технических средств.<br/>
                        2.5. Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных.<br/>
                        2.6. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.<br/>
                        2.7. Оператор — государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и/или осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.<br/>
                    </p>
                    <br/>
                    <br/>
                    <br/>
                    <h5>2. Основные понятия, используемые в Политике.</h5>
                    <br/>
                    <br/>
                    <p className='bodyS_Regular_22'>Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» (далее — Закон о персональных данных) и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных, предпринимаемые Михайловым Иваном Сергеевичем (далее — Оператор).<br/>
                    <br/>
                    <br/>
                    1.1. Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.<br/>
                    1.2. Настоящая политика Оператора в отношении обработки персональных данных (далее — Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта https://thismywebsite.com.<br/>
                    2. Основные понятия, используемые в Политике.<br/>
                    2.1. Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств вычислительной техники.</p>
                </div>
            </section>
        </div>
    )
}