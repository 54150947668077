export const faqItems = [
    {
        question: "На каких платформах работает KeepChat?",
        answer: "На любых устройствах: смартфоны, планшеты, портативные и стационарные компьютеры. Работа поддерживается на мобильных устройствах с помощью приложения, а на персональных компьютерах с помощью веб-клиента."
    },
    {
        question: "Какие требования к мобильным OC?",
        answer: (
            <>
                <p>Android клиент:</p>
                <ul>
                    <li>Операционная система: Android 4.1 или новее;</li>
                    <li>Процессор: ARM V7 и выше;</li>
                    <li>Частота процессора: 1Ghz и выше;</li>
                    <li>Оперативная память: 1Gb и выше;</li>
                    <li>Разрешение экрана: 800x480px и выше.</li>
                </ul>
                <p>iOS клиент:</p>
                <ul>
                    <li>Операционная система: iOS 10 или новее;</li>
                    <li>Поколение iPhone: iPhone SE или iPhone 6S и новее.</li>
                </ul>
            </>
        )
    },
    {
        question: "Можно ли работать одновременно на нескольких устройствах?",
        answer: "Да, пользователь может работать в системе одновременно с нескольких устройств."
    },
    {
        question: "Есть ли у KeepChat какие-либо иностранные сервисы: облака, компоненты и т.д.?",
        answer: "Нет, всё ПО отечественное, безопасность данных наш важнейший приоритет."
    },
    {
        question: "Потерял мобильный телефон. Привязанный Desktop будет работать?",
        answer: "Да, Desktop приложение будет работать какое-то время в этом случае. По истечению некоторого времени могут обновиться ключи шифрования, тогда Desktop-устройство может перестать работать. Точный период этого времени не установлен. В момент регистрации на другом устройстве учетной записи со старым номером телефона пользователя (например, после восстановления сим-карты), Desktop-приложение отвяжется и перестанет работать. Но после перепривязки к аккаунту с той же учетной записью, Desktop-приложение продолжит работать, история сообщений в чатах сохранится."
    },
    {
        question: "Регистрирую cвой номер телефона в KeepChat на новом устройстве (например, при смене телефона).Что будет с учетной записью на старом устройстве?",
        answer: 'Если зарегистрировать свой номер телефона в KeepChat на новом устройстве, то на старом устройстве аккаунт автоматически перестанет быть зарегистрирован ("отвяжется" от номера телефона). При этом вся история чатов будет доступна. На "отвязанном" аккаунте нельзя отправить/получить сообщения, совершить звонок и тд.'
    },
    {
        question: "Можно ли зарегистрироваться в KeepChat под одним номером телефона на разных устройствах?",
        answer: 'Регистрация в KeepChat доступна на одном устройстве с использованием одного номера телефона. Нельзя использовать одну и ту же учетную запись на нескольких устройствах одновременно (исключение - Desktop-приложения). Для регистрации в KeepChat на другом мобильном устройстве нужен другой номер телефона.  В случае привязки одного и того же номера телефона в KeepChat на новом устройстве, учетная запись KeepChat на старом устройстве будет автоматически "отвязана".'
    },
    {
        question: "Можно ли использовать KeepChat на мобильном устройстве без сим-карты?",
        answer: "KeepChat будет работать в том числе и на устройстве без сим-карты (только с использованием wifi). Но для регистрации учетной записи необходимо подтверждение смс-кода."
    },
    {
        question: "Можно ли зарегистрироваться в KeepChat на номер сим-карты, которая далее будет выключена?",
        answer: "Да, можно зарегистрироваться в KeepChat на любой доступный вам номер телефона. Для регистрации достаточно один раз получить смс-код для подтверждения регистрации.  Далее сим-карта не понадобится, если у вас не будет необходимости в перерегистрации."
    },
    {
        question: "Как осуществляется скачивание приложения?",
        answer: "На данный момент мы работаем над тем, чтобы мы появились в самых популярных маркетах (Google и Apple), а пока, установка приложения происходит через ссылки."
    },
    {
        question: "Как обновлять приложение KeepChat?",
        answer: "Чтобы обновить приложение KeepChat достаточно зайти в приложение, когда вышло новое обновление и подтвердить его на всплывающей плашке. Тогда, если есть интернет соединение, приложение автоматически обновится."
    }
];