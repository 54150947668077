import React, { useState, useEffect, useRef } from 'react';
import './ContactForm.css'

export const ContactForm = ({togglePopupSubmittedForm}) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    employees: '',
    message: '',
    recapchaToken: ''
  });
  
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const placeholder = 'Количество сотрудников (Обязательно)' 
  const options = [ 
    {value: 50, label: 'До 50 сотрудников'},
    {value: 200, label: 'До 200 сотрудников'},
    {value: 1000, label: 'До 1000 сотрудников'},
    {value: 7000, label:  'До 7000 сотрудников'}
  ]

  const handleSelectOption = (option) => {
    const {value} = option
    setSelectedOption(option);
    setIsOpen(false);
    setFormData({
      ...formData,
      'employees': value
    });
  };

  const validateName = (name) => {
    console.log('validateName')
    if (name.trim().length < 2 && name.trim().length !== 0) {
        setNameError('Имя не должно быть меньше двух букв.');
    } else {
      setNameError('');
    }
  };

  const validateEmail = (email) => {
    if (!email.trim()) {
      setPhoneError('');
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError('Неверный формат email.');
    } else {
      setEmailError('');
    }
  };

  const validatePhone = (phone) => {
    if (!phone.trim()) {
      setPhoneError('');
      return;
    }
    const phoneRegex = /^\+?[0-9\s\-()]{7,}$/;
    if (!phoneRegex.test(phone)) {
      setPhoneError('Неверный формат телефона.');
    } else {
      setPhoneError('');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    console.log('handleBlur')
    if (name === 'name') {
      validateName(value);
    }

    if (name === 'email') {
      validateEmail(value);
    }

    if (name === 'phone') {
      validatePhone(value);
    }
  };

  useEffect(() => {
    const { name, email, phone, employees } = formData;
    if (name && email && phone && employees && !emailError && !phoneError && !nameError) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formData, emailError, phoneError, nameError]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const buildQuery = function(data) {
    let str = [];
    for (let p in data)
      if (data.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(data[p]));
      }
    return str.join("&");
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const { name, email, phone, employees } = formData;

    if (!name || !email || !phone || !employees) {
      setError('Пожалуйста, заполните все обязательные поля.');
      return;
    }

    if (nameError) {
      setError('Имя не должно быть меньше двух букв.');
      return;
    }

    if (emailError) {
      setError('Исправьте email перед отправкой формы.');
      return;
    }

    if (phoneError) {
      setError('Исправьте номер телефона перед отправкой формы.');
      return;
    }

    setError('');

    window.grecaptcha.enterprise.ready(async () => {
      const token = await window.grecaptcha.enterprise.execute('6LddXFQqAAAAAElT4rVdYsG9v5yRtJdDTpQm8JDy', {action: 'SEND_REQUEST'});
      const data = {...formData, recapchaToken: token}

      fetch("https://chatsoft.ru/forms/process.php", {
        headers: {
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        body: buildQuery(data),
        method: "POST"
      })
          .then(response => response.json())
          .then(data => {
            if (data.success) {
              setSuccess('Форма успешно отправлена!');
              togglePopupSubmittedForm();
              setFormData({
                name: '',
                email: '',
                phone: '',
                company: '',
                employees: '',
                message: '',
                recapchaToken: ''
              });
            } else {
              setError('Ошибка при отправке формы. Попробуйте снова.');
            }
          })
          .catch(error => {
            setError('Произошла ошибка: ' + error.message);
          });
    });
  };

  return (
    <form className="contact-form__form" onSubmit={handleSubmit}>
        <div className="contact-form__row">
        <div className="contact-form__input-wrapper">
            <input 
                type="text" 
                name="name"
                className={`contact-form__input max-width captionL_Regular_16 ${nameError && 'contact-form__input_error'}`}  
                placeholder="Имя (Обязательно)" 
                value={formData.name} 
                onChange={handleChange}
                onBlur={handleBlur}
                required 
            />
            {nameError && 
            <span className="contact-form__error captionL_Regular_16">
              {nameError}
            </span>}
        </div>
        </div>
      <div className="contact-form__row">
        <div className="contact-form__input-wrapper">
          <input 
            type="email" 
            name="email"
            className={`contact-form__input max-width captionL_Regular_16 ${emailError && 'contact-form__input_error'}`} 
            placeholder="E-mail (Обязательно)" 
            value={formData.email} 
            onChange={handleChange}
            onBlur={handleBlur}
            required 
          />
          {emailError && 
          <span className="contact-form__error captionL_Regular_16">
            {emailError}
          </span>}
        </div>
        <div className="contact-form__input-wrapper max-width">
          <input 
            type="tel" 
            name="phone"
            className={`contact-form__input max-width captionL_Regular_16 ${phoneError && 'contact-form__input_error'}`}
            placeholder="Телефон (Обязательно)" 
            value={formData.phone} 
            onChange={handleChange}
            onBlur={handleBlur}
            required 
          />
            {phoneError && 
            <span className="contact-form__error captionL_Regular_16">
              {phoneError}
            </span>}
         </div>
      </div>
      <div className="contact-form__row">
        <input 
          type="text" 
          name="company"
          className="contact-form__input max-width captionL_Regular_16" 
          placeholder="Компания" 
          value={formData.company} 
          onChange={handleChange}
        />
      <div className={`custom-select-container ${selectedOption ? 'selected' : ''} captionL_Regular_16`} ref={selectRef} onClick={() => setIsOpen(!isOpen)}>
      <div className={`custom-select ${isOpen ? 'open' : ''}`}>
        <span className={`custom-select__${selectedOption ? 'label' : 'placeholder'}`}>{selectedOption ? selectedOption.label : placeholder}</span>
        <span className={`arrow ${isOpen ? 'up' : 'down'}`} />
      </div>
      {isOpen && (
        <div className="custom-select-options">
          {options.map((option) => (
            <div
              key={option.value}
              className="custom-select-option captionL_Regular_16"
              onClick={() => handleSelectOption(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>

      </div>
      <textarea 
        name="message"
        className="contact-form__textarea captionL_Regular_16" 
        placeholder="Сообщение" 
        value={formData.message} 
        onChange={handleChange}
        maxLength='500'
      />
      <button disabled={isButtonDisabled} type="submit" className="contact-form__button">
        <span className="bodyXS_Bold_18">Отправить ➔</span>
      </button>
      <span className="contact-form__consent captionL_Regular_16">Нажимая кнопку “Отправить”, вы даете согласие на обработку своих персональных данных.</span>
      {error && <div className="contact-form__error" style={{ color: 'red', marginTop: '20px' }}>{error}</div>}
      {success && <div className="contact-form__success" style={{ color: 'green', marginTop: '20px' }}>{success}</div>}
    </form>
  );
};
