import React, { useState, useRef, useEffect } from 'react';
import { slides } from './constants'
import './Confidentiality.css'

  export const Confidentiality = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideListRef = useRef(null);
    const slideImgListRef = useRef(null);

    const previousSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

    useEffect(() => {
      const slideList = slideListRef.current;
      const slideImgList = slideImgListRef.current;

      if (slideList && slideImgList) {
          const slideHeight = slideList.children[0].offsetHeight;
          const scrollPosition = slideHeight * (currentIndex + 1);

          slideList.scrollTo({
              top: scrollPosition,
              behavior: 'smooth'
          });

          slideImgList.scrollTo({
              top: scrollPosition,
              behavior: 'smooth'
          });
      }

      if (slideList) {
            const slideHeight = slideList.children[0].offsetHeight;
            const slideCount = slideList.children.length;
            const containerHeight = slideList.clientHeight;

            const maxScrollPosition = slideHeight * (slideCount - 1);
            const scrollPosition = Math.min(
                Math.max(0, slideHeight * currentIndex - (containerHeight / 2 - slideHeight / 2)),
                maxScrollPosition
            );

            slideList.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
  }, [currentIndex]);

    const renderedSlides = [
      slides[(currentIndex - 1 + slides.length) % slides.length],
      slides[currentIndex],
      slides[(currentIndex + 1) % slides.length],
  ];
  
    return (
      <section className="confidentiality">
      <div className="confidentiality__container">
          <h3 className="confidentiality__title">
              Ваша конфиденциальность - наш приоритет.<br/>
              Благодаря <u>сквозному шифрованию</u>&nbsp;
              ваши данные и личные сообщения под надежной защитой.
          </h3>

          <div className="confidentiality__slider">
              <div className="confidentiality__slider_wrapper">
                  <div className="slide__list" ref={slideListRef}>
                      {renderedSlides.map((slide, index) => (
                          <div
                              key={index}
                              className={`confidentiality__slide-item ${index === 1 ? 'slide-active' : ''}`}
                              onClick={index === 0 ? previousSlide : nextSlide}
                          >
                              <h4>{slide.title}</h4>
                              <p className='bodyS_Regular_22'>{slide.content}</p>
                          </div>
                      ))}
                  </div>
              </div>

              <div className="confidentiality__slide__img_wrapper">
                  <div className="slide__img_list" ref={slideImgListRef}>
                      {renderedSlides.map((slide, index) => (
                          <img
                              key={index}
                              src={slide.image}
                              alt={slide.title}
                              className={index === 1 ? 'active' : ''}
                          />
                      ))}
                  </div>
              </div>

              <div className="confidentiality__slider_dotlist">
                  {slides.map((_, index) => (
                      <span
                          key={index}
                          className={index === currentIndex ? 'dotItem_active' : ''}
                          onClick={() => setCurrentIndex(index)}
                      ></span>
                  ))}
              </div>
          </div>
      </div>
  </section>
    );
};
