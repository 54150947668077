import React, { useState } from 'react';
import { slides } from './constants'
import { ReactComponent as ArrowLeft } from '../../icons/functions-messenger/arrow-left-circle-line.svg';
import { ReactComponent as ArrowRight } from '../../icons/functions-messenger/arrow-right-circle-fill.svg'
import './Platforms.css'

export const Platforms = () => {

    const [currentIndex, setCurrentIndex] = useState(0);
  
    const goToPreviousSlide = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
      };
    
      const goToNextSlide = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
      };

      const isLeftDisabled = currentIndex === 0;
      const isRightDisabled = currentIndex === 4;

    return (
        <section id="platformsSectionId" className="platforms">
        <div className="platforms__container">
            <div className='platforms__content-wrapper'>
                <h3 className="platforms__title">Доступные<br/> платформы</h3>
                <p className="platforms__subtitle bodyM_Regular_22">Помимо классических платформ,<br/> KeepChat можно установить на “Аврору”.</p>
                <div className="platforms__button-wrapper">
                    <button 
                        className="platforms__button"
                        onClick={goToPreviousSlide}
                        disabled={isLeftDisabled} 
                    >
                        <ArrowLeft className="arrow-left-circle"/>
                    </button>
                    <button 
                        className="platforms__button"
                        onClick={goToNextSlide}
                        disabled={isRightDisabled} 
                    >
                        <ArrowRight className="arrow-right-circle"/>
                    </button>
                </div>
            </div>
            <div className="platforms__slider">
            <div 
              className="platforms__slider-wrapper"
              style={{ transform: `translateX(-${currentIndex * 105}%)`, transition: 'transform 0.5s ease-in-out' }}
            >
                    {slides.map((slide, index) => {
                        return (
                            <div key={index}className="platforms__item platform__box-4">
                                {/* <div className='platforms__item-content'>
                                    <p className='bodyS_Regular_22'>{slide.title}</p>
                                    <h3>{slide.name}</h3>
                                    <p className='bodyS_Regular_22'>{slide.info}</p>
                                </div> */}
                                <img src={slide.image} alt=""/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </section>
    )
}