const slide_1 = (
    <div>
        <div className="slider__img img_1"/>
        {/* <div className="slider__content">
            <div className="slider__content-wrapper">
                <h4 className="slider__content_title">Папки чатов</h4>
                <p className="slider__content_subtitle captionL_Regular_16">Распределяйте чаты по папкам для удобной навигации!</p>
            </div>
            <div className='tag green captionL_Regular_16'>Функции классического мессенджера  </div>
        </div> */}
    </div>
)

const slide_2 = (
    <div>
        <div className="slider__img img_2"/>
        {/* <div className="slider__content">
            <div className="slider__content-wrapper">
                <h4 className="slider__content_title">Упоминания 
                в группах</h4>
                <p className="slider__content_subtitle bodyS_Regular_22">Обращайтесь к конкретному участнику через @ (mention – мэншн), упоминайте любого пользователя.</p>
            </div>
            <div className='tag orange captionL_Regular_16'>Функции классического мессенджера  </div>
        </div> */}
    </div>
)

const slide_3 = (
    <div>
        <div className="slider__img img_3"/>
        {/* <div className="slider__content">
            <div className="slider__content-wrapper">
                <h4 className="slider__content_title">Поиск по сообщениям</h4>
                <p className="slider__content_subtitle bodyS_Regular_22">Быстро находите нужный чат или сообщение по названию или упоминанию слова.</p>
            </div>
            <div className='tag red captionL_Regular_16'>Функции классического мессенджера  </div>
        </div> */}
    </div>
)

const slide_4 = (
    <div>
        <div className="slider__img img_4"/>
        {/* <div className="slider__content">
            <div className="slider__content-wrapper">
                <h4 className="slider__content_title">Отправка
                реакций</h4>
                <p className="slider__content_subtitle bodyS_Regular_22">Оставляйте реакции под сообщением. Также можно посмотреть кто именно оставил реакцию в группе.</p>
            </div>
            <div className='tag blue captionL_Regular_16'>Функции классического мессенджера  </div>
        </div> */}
    </div>
)

export const slides = [
    slide_1,
    slide_2,
    slide_3,
    slide_4
];