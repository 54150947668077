export const slides = [
    {
        title: "Запрет скриншотов",
        content: "Сохраняйте свою конфиденциальность, запретив участникам группы или в личных сообщениях делать скриншот.",
        image: "/confidentiality-2.png"
    },
    {
        title: "Таймер блокировки экрана",
        content: "Защитите свои данные с помощью пароля. Теперь вы можете ставить блокировку отдельно на наше приложение!",
        image: "/confidentiality-1.png"
    },
    {
        title: "PIN-код для удаления переписок",
        content: "Создайте специальный PIN-код для экстренных ситуаций. Введя его в приложение — вы очистите всю историю.",
        image: "/confidentiality-3.png"
    },
    {
        title: "Таймер автоматического удаления сообщений и файлов",
        content: "Сохраняйте свою конфиденциальность с помощью функции удаления сообщения, после прочтения собеседником.",
        image: "/confidentiality-4.png"
    },
    {
        title: "Общение только с проверенными пользователями",
        content: "Обменивайтесь кодами безопасности только с теми участниками, кого знаете.",
        image: "/confidentiality-5.png"
    },
    {
        title: "Асинхронное управление Desktop-платформой",
        content: "Сохраните данные на одном устройстве или удалите на обоих.",
        image: "/confidentiality-6.png"
    },
  ];