import './ContactForm.css'

export const ContactFormWrapper = ({children}) => {
    return (
        <section className="contact-form">
            <div className="contact-form__container">
            <h3 className="contact-form__title">Оставить заявку</h3>
            <p className="contact-form__subtitle bodyM_Regular_22">Получите консультацию по внедрению KeepChat в вашей компании.</p>
                {children}
            </div>
        </section>
    )
}