import React, { useState } from 'react';
import { faqItems } from './constants';
import { Link } from 'react-router-dom';

import './Faq.css'

export const FAQ = () => {
    const [openItems, setOpenItems] = useState({});

    const handleToggle = (index) => {
        setOpenItems(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <section id="faqSectionId" className="faq">
            <div className="faq__container">
                <div className="faq__title-wrapper">
                    <h3 className="faq__title">Часто задаваемые </h3>
                    <h3 className='faq__title'><Link to="/faq">  вопросы</Link> <span/></h3>
                </div>
                <div className="faq__container-wrapper">
                    {faqItems.map((item, index) => (
                        <div className="faq__item" key={index}>
                            <h4 className="faq__question" onClick={() => handleToggle(index)}>
                                {item.question}
                                <span className={`faq__arrow ${openItems[index] ? 'open' : ''}`}><img src="/arrow-down-s-fill.png" alt=""/></span>
                            </h4>
                            {openItems[index] && (
                                <div className="faq__answer bodyM_Regular_22">
                                    {item.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};