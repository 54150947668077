export const faqItems = [
    {
        question: "На каких платформах работает KeepChat?",
        answer: "На любых устройствах: смартфоны, планшеты, портативные и стационарные компьютеры. Работа поддерживается на мобильных устройствах с помощью приложения, а на персональных компьютерах с помощью веб-клиента."
    },
    {
        question: "Какие требования к мобильным OC?",
        answer: (
            <>
                <p>Android клиент:</p>
                <ul>
                    <li>Операционная система: Android 4.1 или новее;</li>
                    <li>Процессор: ARM V7 и выше;</li>
                    <li>Частота процессора: 1Ghz и выше;</li>
                    <li>Оперативная память: 1Gb и выше;</li>
                    <li>Разрешение экрана: 800x480px и выше.</li>
                </ul>
                <p>iOS клиент:</p>
                <ul>
                    <li>Операционная система: iOS 10 или новее;</li>
                    <li>Поколение iPhone: iPhone SE или iPhone 6S и новее.</li>
                </ul>
            </>
        )
    },
    {
        question: "Можно ли работать одновременно на нескольких устройствах?",
        answer: "Да, пользователь может работать в системе одновременно с нескольких устройств."
    },
    {
        question: "Есть ли у KeepChat какие-либо иностранные сервисы: облака, компоненты и т.д.?",
        answer: "Нет, всё ПО отечественное, безопасность данных наш важнейший приоритет."
    },
];