import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage/HomePage'
import { DocumentPage } from './pages/documentPage/DocumentPage';
import { FAQPage } from './pages/faqPage/FAQPage';
import { UserAgreementPage } from './pages/userAgreementPage/UserAgreementPage';
import { PrivacyPolicyPage } from './pages/privacyPolicyPage/PrivacyPolicyPage';

function App() {
  return (
    <Router>
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/document" element={<DocumentPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/user-agreement" element={<UserAgreementPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
    </Router>
  );
}

export default App;
