import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { faqItems } from './constants';

import './FAQPage.css'

export const FAQPage = () => {
    const [openItems, setOpenItems] = useState({});

    const handleToggle = (index) => {
        setOpenItems(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <div className="faq__main">
            <header className="header__container">
                <div className="header__logo"><svg className="logo"/></div>
                    <span className="button-text"><Link  to="/" className="header__link">Назад</Link></span>
            </header>
            <section className="faq__container">
                <h3 className='faq__title'>Часто задаваемые вопросы </h3>
                <div className='faq__content'>
                <div className="faq__container-wrapper">
                    {faqItems.map((item, index) => (
                        <div className="faq__item" key={index}>
                            <h4 className="faq__question" onClick={() => handleToggle(index)}>
                                {item.question}
                                <span className={`faq__arrow ${openItems[index] ? 'open' : ''}`}><img src="/arrow-down-s-fill.png" alt=""/></span>
                            </h4>
                            {openItems[index] && (
                                <div className="faq__answer bodyM_Regular_22">
                                    {item.answer}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                </div>
            </section>
        </div>
    )
}