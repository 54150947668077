export const slides = [
    {
        title: "Мобильное устройство/планше",
        name: "Аврора",
        info: "Версия Авроры не менее 4.0.2.",
        image: "/aurora.png"
    },
    {
        title: "ПК",
        name: "Windows",
        info: "Требуется Windows 10 или новее.",
        image: "/win.png"
    },
    {
        title: "Компьютер",
        name: "macOS",
        info: "Требуется macOS 11 или новее.",
        image: "/mac.png"
    },
    {
        title: "Мобильное устройство/планшет",
        name: "Android",
        info: "Мин. системные требования для версии 2.22.13.77: ОС Android 4.0.3 или новее",
        image: "/android.png"
    },
    {
        title: "Мобильное устройство/планше",
        name: "iOS",
        info: "Минимальные системные требования для версии 22.13.74: iOS 12.0 или новее",
        image: "/ios.png"
    }
  ];
